<template>
    <div class="form-container">
        <v-form v-model="isValid">
            <vertical-form-table v-model="form" v-bind="{ items }"></vertical-form-table>

            <div class="btn-wrap btn-wrap--lg">
                <v-row justify="center" class="row--sm">
                    <v-col cols="6" sm="auto">
                        <v-btn tile x-large outlined color="grey lighten-3" class="w-100 min-w-sm-160px" @click="$router.go(-1)"><span class="grey--text">취소</span></v-btn>
                    </v-col>
                    <v-col cols="6" sm="auto">
                        <v-btn tile x-large color="grey darken-4" class="w-100 min-w-sm-160px" v-bind="{ disabled }" @click="$emit('save')"><span class="white--text">작성하기</span></v-btn>
                    </v-col>
                </v-row>
            </div>
        </v-form>

        <!-- <div class="table-style table-style--default">
            <myinfo-modify-row label="이름">
                {{ form.meta.name }}
            </myinfo-modify-row>

            <myinfo-modify-row label="연락처">
                <div class="mw-lg-500px">
                    {{ form.meta.phone }}
                </div>
            </myinfo-modify-row>
            <myinfo-modify-row label="이메일">
                <div class="mw-lg-500px">
                    {{ form.meta.email }}
                </div>
            </myinfo-modify-row>
            <myinfo-modify-row label="제목">
                <v-text-field v-model="form.subject" outlined persistent-placeholder hide-details class="w-100 mw-lg-500px" />
            </myinfo-modify-row>
            <myinfo-modify-row label="내용">
                <v-textarea v-model="form.content" outlined persistent-placeholder hide-details />
            </myinfo-modify-row>
        </div>

        <div class="mt-30 mt-md-50">
            <div class="v-btn--group v-btn--group--large">
                <v-btn x-large outlined color="grey-cb" class="w-100 mw-220px" @click="$router.go(-1)"><span class="primary--text">취소</span></v-btn>
                <v-btn x-large color="primary" class="w-100 mw-220px" @click="$emit('save')">문의하기</v-btn>
            </div>
        </div> -->
    </div>
</template>

<script>
import { initUser, rules } from "@/assets/variables";

import PageSection from "@/sets/styles/pages/page-section.vue";
import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";
import TermsOfAgreementsShort from "@/components/client/terms/terms-of-agreements-short.vue";
import TitTertiary from "@/components/publish/parents/typography/tit-tertiary.vue";
import TxtPrimary from "@/components/publish/parents/typography/txt-primary.vue";
// import ImagesInput from "@/components/console/dumb/images-input.vue";
// import MyinfoModifyRow from "@/components/client/mypage/myinfo-modify-row.vue";

import api from "@/api";

const items = [
    {
        key: "meta.",
        term: "제목",
        type: "text",
        required: false,
        placeholder: "제목을 입력하세요.",
    },
    {
        key: "meta.",
        term: "공개여부",
        type: "radio",
        required: false,
    },
    {
        key: "meta.name",
        term: "이름",
        type: "text",
        required: false,
        placeholder: "이름을 입력하세요.",
        // rules: rules.name,
    },
    {
        key: "meta.password",
        term: "비밀번호",
        type: "text",
        required: false,
        placeholder: "게시글 비밀번호를 입력하세요.",
        // rules: rules.name,
    },
    {
        key: "meta.",
        term: "내용",
        type: "textarea",
        required: false,
        placeholder: "내용을 입력하세요.",
        // rules: rules.name,
    },
    // {
    //     key: "meta.message",
    //     term: "Message",
    //     type: "textarea",
    //     required: true,
    //     placeholder: "Type an inquiry message.",
    // },
    // {
    //     key: "meta.company",
    //     term: "Company",
    //     type: "text",
    //     required: true,
    //     placeholder: "Type a company name.",
    // },
].map((item) => ({ ...item, outlined: true }));

export default {
    components: {
        PageSection,
        VerticalFormTable,
        TermsOfAgreementsShort,
        TitTertiary,
        TxtPrimary,
        // ImagesInput,
        // MyinfoModifyRow,
    },
    props: {
        value: { type: Object, default: null },
    },
    data() {
        return {
            work: null,
            form: this.$props.value,

            // form: initUser(),
            items,
            isValid: false,
            isTermsValid: undefined,
        };
    },
    computed: {
        _work() {
            return this.$route.query._work;
        },

        disabled() {
            return !this.isValid || !this.isTermsValid;
        },
    },
    // mounted() {
    //     this.init();
    // },
    watch: {
        value() {
            this.board = this.value;
        },
    },
    methods: {
        async init() {
            const { _work: _id } = this;
            const { work } = await api.v1.auction.works.get({ _id });
            this.work = work;
        },

        validates() {
            try {
                switch (this.isTermsValid) {
                    case undefined:
                        throw new Error("이용약관을 확인해주세요");
                    case false:
                        throw new Error("필수 이용약관에 모두 동의해주세요");
                    default:
                        break;
                }
                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
    },
};
</script>
